<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        Add Funded Price
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <!-- form -->
    <validation-observer ref="NgosFormvalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col md="4">
            <b-form-group
              label="Type"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Type"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="NgosForm.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                  :options="TypeList"
                  label="name"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="NgosForm.category_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="type"
                  :options="categoriesList"
                  :reduce="(val) => val.id"
                  @input="getCoursesByCategory(NgosForm.category_id)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Course"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Course"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="NgosForm.course_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="coursesList"
                  :reduce="(val) => val.id"
                >
                  <template

                    #no-options="{ search, searching, loading }"
                  >
                    Select Category First ...
                  </template>

                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="NgosForm.type==0"
            md="3"
          >
            <b-form-group
              label="City"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="NgosForm.city_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="citiesList"
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Percentage"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Percentage"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="NgosForm.price"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton,
  BFormTags,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, reactive } from '@vue/composition-api'
import Vue from 'vue'
import Editor from '@tinymce/tinymce-vue'
import vSelect from 'vue-select'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const categoriesList = ref([])
    const coursesList = ref([])
    const citiesList = ref([])
    const VisitorList = ref([])
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const isLoading = ref(false)
    const { route } = useRouter()

    const TypeList = [
      {
        name: 'online',
        id: '1',
      },
      {
        name: 'classic',
        id: '0',
      },

    ]
    const { id } = route.value.params
    const NgosForm = reactive({
      price: '',
      type: '',
      course_id: '',
      city_id: '',
      online: '',
      category_id: '',
    })
    store.dispatch('cities/cititesList').then(response => {
      citiesList.value = response.data
    })

    store.dispatch('categories/AllCategory').then(response => {
      categoriesList.value = response.data.data
    })

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const NgosFormvalidate = ref()
    // store.dispatch('setting/getAccerditedPage').then(response => {
    //   form.value = response.page_content

    //   form.value.meta_keywords = response.page_content.meta_keywords.split(',')
    // })

    const save = () => {
      const formData = new FormData()

      NgosFormvalidate.value.validate().then(success => {
        if (success) {
          isLoading.value = true
          formData.append('price', NgosForm.price)

          formData.append('online', NgosForm.type)
          if (NgosForm.type == 0) {
            formData.append('city_id', NgosForm.city_id)
          }

          formData.append('course_id', NgosForm.course_id)

          formData.append('ngo_id', id)
          store.dispatch('setting/addNgosPrice', { formData })
            .then(response => {
          
              isLoading.value = false
              Vue.swal({
                title: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
        }
      })
    }
    const getCoursesByCategory = id => {
      NgosForm.course_id = ''

      store
        .dispatch('courses/getAllCoursesList', {
          'filter[category_id]': id,
          'filter[online]': NgosForm.type,
        })
        .then(response => {
          coursesList.value = response.data
        })
    }
    const removeSpace = target => {
      NgosForm.value.slug = target.toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    return {
      id,
      isLoading,
      TypeList,
      removeSpace,
      getCoursesByCategory,
      refInputEl,
      refPreviewEl,
      refInputE2,
      categoriesList,
      coursesList,
      citiesList,
      refPreviewE2,
      inputImageRendererTab,
      Tabimage,
      NgosForm,
      inputImageRenderer,
      NgosFormvalidate,
      VisitorList,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    vSelect,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,

    BForm,

    BFormTags,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    Editor,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
